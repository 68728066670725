import React, { useContext } from 'react';
import { useState } from 'react';
import Card, { CardBody } from '../bootstrap/Card';
import { sendEmailVerification } from 'firebase/auth';
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';
import Button from '../bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../bootstrap/Modal';
import Alert from '../bootstrap/Alert';

const DisplayPricingPlans = () => {
	const [isHovered, setIsHovered] = useState(false);
	const [isBoxHovered, setIsBoxHovered] = useState(false);
	const [isBox2Hovered, setIsBox2Hovered] = useState(false);
	const [isBox3Hovered, setIsBox3Hovered] = useState(false);
	const { user, setUser, setVerified } = useContext(AuthContext);
	const [modalState, setModalState] = useState(false);
	const navigate = useNavigate();

	const handleModal = () => {
		setModalState(!modalState);
	};
	const handleEmail = () => {
		if (user) {
			console.log(user);
			sendEmailVerification(user)
				.then(() => {
					// Handle success, maybe display a message to the user
					showNotification(
						'Success',
						'Please check your inbox for the verification email',
						'success',
					);
					if (setUser) {
						setUser(null);
						setVerified(false);
					}
					navigate('/submit-email');
				})
				.catch((error) => {
					// Handle errors, such as network issues or invalid user state
					showNotification('Error', 'Please sign in to a valid account to send an email');
				});
		} else {
			showNotification('Error', 'Please sign in to a valid account to send an email');
			// Optionally, handle the UI feedback about no user being logged in
		}
	};

	const handleNavigate = () => {
		if (user === null) {
			navigate('/submit-email');
			return;
		}
		if (user && !user.emailVerified) {
			setModalState(!modalState);
		}
		if (user.emailVerified) {
			navigate('/view-plans');
		}
	};
	return (
		<Card className='d-flex justify-content-center align-items-center my-5'>
			<CardBody>
				<div
					className='row d-flex justify-content-center align-items-center my-2'
					style={{
						transform: isHovered ? 'scale(1.02)' : 'scale(1)', // Scale up on hover
						boxShadow: isHovered
							? '0 0 15px 5px rgba(81, 108, 250, 0.5)' // Glow effect on hover
							: '', // Subtle shadow when not hovered
						padding: '5px',
						borderRadius: '30px', // Rounded corners for visual effect
						transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
					}}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}>
					<div className='col-md-3'>
						<Card>
							<CardBody>
								<div
									onClick={() => {
										handleNavigate();
									}}
									className='row pt-5 g-4 text-center clickable'
									style={{
										transform: isBoxHovered ? 'scale(1.02)' : 'scale(1)', // Scale up on hover
										boxShadow: isBoxHovered
											? '0 0 15px 5px rgba(70, 188, 170, 0.5)' // Glow effect on hover
											: '', // Subtle shadow when not hovered
										padding: '20px',
										borderRadius: '30px', // Rounded corners for visual effect
										transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
										cursor: 'pointer',
									}}
									onMouseEnter={() => setIsBoxHovered(true)}
									onMouseLeave={() => setIsBoxHovered(false)}>
									<div className='col-12'>
										<Icon icon='CustomRocketLaunch' size='7x' color='info' />
									</div>
									<div className='col-12'>
										<h2>Artist</h2>
									</div>
									<div className='col-12'>
										<h3 className='display-1 fw-bold'>
											<span className='display-4 fw-bold'>$</span>5
											<span className='display-6'>/mo</span>
										</h3>
									</div>
									<div className='col-12'>
										<div className='lead'>
											<Icon icon='Done Outline' color='success' /> 5 tracks a
											month
										</div>
										<div className='lead'>
											<Icon icon='Done Outline' color='success' /> 50 / 50
											split
										</div>
										<div className='lead '>
											<Icon icon='Close' color='danger' /> Gaming Marketplaces
										</div>
									</div>

									<div className='col-12'></div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-md-3'>
						<Card>
							<CardBody>
								<div
									onClick={() => {
										handleNavigate();
									}}
									className='row pt-5 g-4 text-center'
									style={{
										transform: isBox2Hovered ? 'scale(1.02)' : 'scale(1)', // Scale up on hover
										boxShadow: isBox2Hovered
											? '0 0 15px 5px rgba(70, 188, 170, 0.5)' // Glow effect on hover
											: '', // Subtle shadow when not hovered
										padding: '20px',
										borderRadius: '30px', // Rounded corners for visual effect
										transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
										cursor: 'pointer',
									}}
									onMouseEnter={() => setIsBox2Hovered(true)}
									onMouseLeave={() => setIsBox2Hovered(false)}>
									<div className='col-12'>
										<Icon icon='Maps Home Work' size='7x' color='success' />
									</div>
									<div className='col-12'>
										<h2>Producer</h2>
									</div>
									<div className='col-12'>
										<h3 className='display-1 fw-bold'>
											<span className='display-4 fw-bold'>$</span>10
											<span className='display-6'>/mo</span>
										</h3>
									</div>
									<div className='col-12'>
										<div className='lead'>
											<Icon icon='Done Outline' color='success' /> 10 tracks a
											month
										</div>
										<div className='lead'>
											<Icon icon='Done Outline' color='success' /> 40 / 60
										</div>
										<div className='lead '>
											<Icon icon='Done Outline' color='success' /> Every
											endpoint
										</div>
									</div>

									<div className='col-12'></div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-md-3'>
						<Card>
							<CardBody>
								<div
									onClick={() => {
										handleNavigate();
									}}
									className='row pt-5 g-4 text-center'
									style={{
										transform: isBox3Hovered ? 'scale(1.02)' : 'scale(1)', // Scale up on hover
										boxShadow: isBox3Hovered
											? '0 0 15px 5px rgba(70, 188, 170, 0.5)' // Glow effect on hover
											: '', // Subtle shadow when not hovered
										padding: '20px',
										borderRadius: '30px', // Rounded corners for visual effect
										transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
										cursor: 'pointer',
									}}
									onMouseEnter={() => setIsBox3Hovered(true)}
									onMouseLeave={() => setIsBox3Hovered(false)}>
									<div className='col-12'>
										<Icon icon='Label' size='7x' color='info' />
									</div>
									<div className='col-12'>
										<h2>Label</h2>
									</div>
									<div className='col-12'>
										<h3 className='display-1 fw-bold'>
											<span className='display-4 fw-bold'>$</span>50
											<span className='display-6'>/mo</span>
										</h3>
									</div>
									<div className='col-12'>
										<div className='lead'>
											<Icon icon='Done Outline' color='success' /> 50 tracks a
											month
										</div>
										<div className='lead'>
											<Icon icon='Done Outline' color='success' /> 70 / 30
										</div>
										<div className='lead '>
											<Icon icon='Done Outline' color='success' /> Every
											endpoint
										</div>
									</div>

									<div className='col-12'></div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</CardBody>
			<p className='h3 mb-3'>
				How much would it cost you to register with every platform we distribute to?
				<strong> $596</strong>
			</p>
			<Modal
				isOpen={modalState}
				setIsOpen={handleModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>File Section</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Alert color='danger' icon='warning'>
							Continuing with the email verification process will log you out.
						</Alert>

						<p>Once the link in the email has been clicked, continue to log back in</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='success' onClick={handleEmail}>
						Send verification Email
					</Button>
				</ModalFooter>
			</Modal>
		</Card>
	);
};

export default DisplayPricingPlans;
