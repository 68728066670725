import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ApexOptions } from 'apexcharts';
import Checks from '../../bootstrap/forms/Checks';
import Chart from '../../extras/Chart';
import Badge from '../../bootstrap/Badge';
import Button from '../../bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { CardTitle } from '../../bootstrap/Card';
import Alert from '../../bootstrap/Alert';
// this is the component I use to list everything in the my submissions page

// TODO -- I need to make the component clickable where it renders a new component that shows the correct information for the row (submission) I have clicked

// how am I going to achieve such a thing? Fuck knows its nebulose
interface MyCommonTableRowProps {
	id: string;
	name: string;
	handleModal: (id: string) => void;
}
const MyCommonTableRow: FC<MyCommonTableRowProps> = ({ id, name, handleModal }) => {
	const { darkModeStatus } = useDarkMode();

	const naviate = useNavigate;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSTModalOpen, setIsSTModalOpen] = useState(false);

	const handleModals = (name: string) => {
		if (name == 'BMI') {
			toggleModal;
		} else {
			toggleSongTrust;
		}
	};
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};
	const toggleSongTrust = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleClick = () => {
		// Prevent default action if needed
		handleModal(id); // Call handleModal with the submission ID
	};
	return (
		<tr onClick={() => handleModal(id)} style={{ cursor: 'pointer' }}>
			<th scope='row' aria-label='Check'>
				<Checks
					id={id.toString()}
					name={name}
					value={id}
					style={{ width: '20px', height: '20px' }}
				/>
			</th>
			<th scope='row'>{id}</th>

			<td>
				<div>
					<Alert color='success' className='d-flex justify-content-center' isLight>
						<CardTitle color='success'>Submitted</CardTitle>
					</Alert>
				</div>
			</td>

			<td className='h5'></td>
			<td />
		</tr>
	);
};

export default MyCommonTableRow;
