import React, { FC, startTransition, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import firebase from '../../../Firebase/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Chart, { IChartOptions } from '../../../components/extras/Chart';
import Icon from '../../../components/icon/Icon';
import MRLogo from '../../../assets/img/blue 2 low top.png';
import { average, priceFormat } from '../../../helpers/helpers';
import PercentComparison from '../../../components/extras/PercentComparison';
import { signOut } from 'firebase/auth';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import showNotification from '../../extras/showNotification';
import Button from '../../bootstrap/Button';

const CommonDashboardIncome = () => {
	const { darkModeStatus } = useDarkMode();
	const [amountToBePayed, setAmountToBePayed] = useState(0);
	const [numberOfUploadsRemaining, setNumberOfUploadsRemaining] = useState(0);
	const [totalFilesUploaded, setTotalFilesUploaded] = useState(0);
	const [totalSubmissions, setTotalSubmissions] = useState(0);
	const [whichPlanActive, setWhichPlanActive] = useState('');
	const { user, isActivePlan, tracksRemaining, setUser, setVerified, setActivePlan } =
		useContext(AuthContext);
	const { db, auth } = firebase;
	const navigate = useNavigate();
	const [sales, setSales] = useState<IChartOptions>({
		series: [
			{
				data: [34, 32, 36, 34, 34],
			},
		],
		options: {
			colors: [process.env.REACT_APP_WARNING_COLOR],
			chart: {
				type: 'line',
				width: '100%',
				height: 150,
				sparkline: {
					enabled: true,
				},
			},
			tooltip: {
				theme: 'dark',
				fixed: {
					enabled: false,
				},
				x: {
					show: false,
				},
				y: {
					title: {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						formatter(seriesName: any) {
							return '';
						},
					},
				},
			},
			stroke: {
				curve: 'smooth',
				width: 2,
			},
		},
		sales: {
			compare: 24,
		},
		campaigns: {
			price: 3265.72,
			compare: 5000,
		},
		coupons: {
			price: 2654.2,
			compare: 2300,
		},
	});
	useEffect(() => {
		if (!user || !user.emailVerified || !isActivePlan) {
			handleLogout();
		} else {
			checkData();
		}
	}),
		[];

	const checkData = async () => {
		console.log(user?.uid);
		if (user && user.emailVerified && isActivePlan) {
			const userDocRef = doc(db, 'users', user.uid);
			try {
				const docSnap = await getDoc(userDocRef);
				if (docSnap.exists()) {
					const returnedAmount = docSnap.data().amountToBePayed;
					const numberOfFilesUploaded = docSnap.data().numberOfFilesUploaded;
					const numberOFSubmissions = docSnap.data().numberOfSubmissions;
					const returnedAmountUploaded = docSnap.data().numberOfUploadsRemaining;
					const planActive = docSnap.data().currentPlanName;
					setTotalFilesUploaded(numberOfFilesUploaded);
					setTotalSubmissions(numberOFSubmissions);
					setNumberOfUploadsRemaining(returnedAmountUploaded);
					setThePlanActive(planActive);
				} else {
					console.log('No such document!');
					return null;
				}
			} catch (error) {
				console.error('Error getting document:', error);
				return null;
			}
		} else {
			console.log('User is not logged in or email is not verified');
			return null;
		}
	};
	const handleLogout = async () => {
		if (setUser) {
			setUser(null);
		}
		console.log('logges');
		setVerified(false);
		setActivePlan(false);
		await signOut(auth);
		navigate('/submit-email');
	};
	const setThePlanActive = (plan: string) => {
		setWhichPlanActive(plan);
	};

	function compareLabel(amount = -1, name = false) {
		return name ? 'week' : dayjs().add(amount, 'week').format('w [th week]');
	}
	return (
		<Card stretch className='mt-5'>
			<CardHeader>
				<CardLabel icon='Account' iconColor='success'>
					<CardTitle tag='div' className='h5'>
						Account
					</CardTitle>
					<CardSubTitle tag='div' className='h6'>
						Overview
					</CardSubTitle>
				</CardLabel>
			</CardHeader>

			<div className='row'>
				<div className='col-md-6'>
					<Card
						className={classNames('transition-base rounded-2 mb-4 text-dark', {
							'bg-l25-secondary bg-l10-secondary-hover': !darkModeStatus,
							'bg-lo50-secondary bg-lo25-secondary-hover': darkModeStatus,
						})}
						shadow='sm'>
						<CardHeader className='bg-transparent'>
							<CardLabel>
								<CardTitle tag='div' className='h5'>
									Number of uploads remaining
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='d-flex align-items-center'>
								<div className='flex-shrink-0'>
									<Icon icon='Upload' size='4x' color='secondary' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-3 mb-0'>
										{numberOfUploadsRemaining}
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>

				<div className='col-md-6'>
					<Card
						className={classNames('transition-base rounded-2 mb-0 text-dark', {
							'bg-l25-primary bg-l10-primary-hover': !darkModeStatus,
							'bg-lo50-primary bg-lo25-primary-hover': darkModeStatus,
						})}
						shadow='sm'>
						<CardHeader className='bg-transparent'>
							<CardLabel>
								<CardTitle tag='div' className='h5'>
									Account email
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='d-flex align-items-center pb-3'>
								<div className='flex-shrink-0'>
									<Icon icon='Email' size='4x' color='primary' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-3 mb-0'>{user?.email}</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>

			<CardBody>
				<div className='row g-4 '>
					<div className='col-md-6'>
						<Card
							className={classNames('transition-base rounded-2 mb-0 text-dark ', {
								'bg-l25-warning bg-l10-warning-hover': !darkModeStatus,
								'bg-lo50-warning bg-lo25-warning-hover': darkModeStatus,
							})}
							stretch
							shadow='sm'>
							<CardHeader className='bg-transparent'>
								<CardLabel>
									<CardTitle tag='div' className='h5'>
										Next quarterly payout
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody className=''>
								<div className='d-flex align-items-center justify-content-center'>
									<div className='flex-shrink-0'>
										<Icon icon='AttachMoney' size='4x' color='warning' />
									</div>
									<div className='flex-grow-1'>
										<div className='fw-bold fs-3 mb-0'>
											{amountToBePayed}.00
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-md-6'>
						<Card
							className={classNames('transition-base rounded-2 mb-4 text-dark', {
								'bg-l25-secondary bg-l10-secondary-hover': !darkModeStatus,
								'bg-lo50-secondary bg-lo25-secondary-hover': darkModeStatus,
							})}
							shadow='sm'>
							<CardHeader className='bg-transparent'>
								<CardLabel>
									<CardTitle tag='div' className='h5'>
										Number of submissions
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='d-flex align-items-center'>
									<div className='flex-shrink-0'>
										<Icon icon='Inbox' size='4x' color='secondary' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-3 mb-0'>{totalSubmissions}</div>
									</div>
								</div>
							</CardBody>
						</Card>
						<Card
							className={classNames('transition-base rounded-2 mb-0 text-dark', {
								'bg-l25-primary bg-l10-primary-hover': !darkModeStatus,
								'bg-lo50-primary bg-lo25-primary-hover': darkModeStatus,
							})}
							shadow='sm'>
							<CardHeader className='bg-transparent'>
								<CardLabel>
									<CardTitle tag='div' className='h5'>
										Number of files uploaded
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='d-flex align-items-center pb-3'>
									<div className='flex-shrink-0'>
										<Icon icon='Info' size='4x' color='primary' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-3 mb-0'>
											{totalFilesUploaded}
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</CardBody>
			<div className='w-100 d-flex justify-content-center mb-3'>
				<Card
					className={classNames(
						'transition-base rounded-2 mb-0 text-dark d-flex justify-content-center w-50',
						{
							'bg-l25-primary bg-l10-primary-hover': !darkModeStatus,
							'bg-lo50-primary bg-lo25-primary-hover': darkModeStatus,
						},
					)}
					shadow='sm'>
					<CardHeader className='bg-transparent justify-content-center'>
						<CardLabel>
							<CardTitle tag='div' className='h5'>
								Not enough uploads? View the options below
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='d-flex justify-content-center'>
						{/* {whichPlanActive === 'Artist' && (
							<div className='d-flex align-items-center  pb-3 mx-5'>
								<div className='flex-shrink-0'></div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-3 mb-0'>
										<Button
											color='info'
											isOutline
											icon='Approval'
											onClick={() => {
												startTransition(() => {
													navigate('/upgrade-producer');
												});
											}}>
											View Producer plan
										</Button>
									</div>
								</div>
							</div>
						)}

						{(whichPlanActive === 'Artist' || whichPlanActive === 'Producer') && (
							<div className='d-flex align-items-center pb-3 mx-5'>
								<div className='flex-shrink-0'></div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-3 mb-0'>
										<Button
											color='info'
											isOutline
											icon='Approval'
											onClick={() => {
												startTransition(() => {
													navigate('/upgrade-label');
												});
											}}>
											View Label plan
										</Button>
									</div>
								</div>
							</div>
						)}
						{whichPlanActive === 'Label' && (
							<div className='d-flex align-items-center pb-3 mx-5'>
								<div className='flex-shrink-0'>
									<Icon icon='Info' size='4x' color='primary' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<CardTitle tag='div' className='h5'>
										You've reached the highest tier available
									</CardTitle>
									<div className='fw-bold fs-3 mb-0'>
										<p className='h5'>
											But hang tight, there are more options in development to
											attain more uploads
										</p>
									</div>
								</div>
							</div>
						)} */}
						<div className='d-flex align-items-center pb-3 mx-5'>
							<div className='flex-shrink-0'>
								<Icon icon='Info' size='4x' color='primary' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<CardTitle tag='div' className='h5'>
									Ugrade plan currently unavilable
								</CardTitle>
								<div className='fw-bold fs-3 mb-0'>
									<p className='h5'>
										But hang tight, there are more options in development to
										attain more uploads
									</p>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		</Card>
	);
};

export default CommonDashboardIncome;
