import React, { ReactNode, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPagesMenu, getDemoPagesMenu, pageLayoutTypesPagesMenu } from '../../../menu';
import MRLogo from '../../../assets/img/blue 2 low top.png';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';
import { getMyPagesMenu } from '../../../menu';
import AuthContext from '../../../contexts/authContext';
import { CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus, mobileDesign } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { user, setUser, isVerified, setVerified, setActivePlan, isActivePlan } =
		useContext(AuthContext);
	const menu = getDemoPagesMenu(isActivePlan, isVerified, user);
	const { t } = useTranslation(['translation', 'menu']);
	const secondMenu = getMyPagesMenu(isActivePlan, isVerified, user);

	return (
		<Aside>
			<AsideHead>
				<div style={{ marginBottom: '50px' }} />
				<img src={MRLogo} style={{ width: '180px' }} />
			</AsideHead>
			<div style={{ paddingTop: '30%' }}></div>
			<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />

				{true && (
					<>
						<Navigation menu={secondMenu} id='aside-menu' />
					</>
				)}
			</AsideBody>
			<AsideFoot>
				<div className='d-flex justify-content-center'>
					<CardSubTitle>©2024 MELODY RIGHTS</CardSubTitle>
				</div>
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
