import React, { startTransition, useContext, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'; // <-- Import react-router-dom first
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { sendEmailVerification } from 'firebase/auth';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import classNames from 'classnames';
// eslint-disable-next-line import/order
import PageWrapper from '../../layout/PageWrapper/PageWrapper'; // <-- Now import your local components
import Page from '../../layout/Page/Page';
import Button from '../bootstrap/Button';
import AuthContext from '../../contexts/authContext';
import ComparisonTable from './ComparisonTable';
import showNotification from '../extras/showNotification';
import Blue from '../../assets/img/blue 2 low top.png';
import RichieWebp from '../../assets/img/wanna/susy/Melody Rights Logo Loop.mp4';
import StorybookLogo from '../../assets/img/colorNotes.png';
import StorybookLogoWebp from '../../assets/img/colorNotes.png';
import MRComparison from '../../assets/img/Melody Rights Comparison Table New Dark.jpg';
import ThemeContext from '../../contexts/themeContext';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import Alert from '../bootstrap/Alert';
import CompetitorImage from '../../assets/img/Melody Rights Competitors Image.png';

const useStyles = createUseStyles({
	// stylelint-disable
	intro: {
		zIndex: 200,
		overflow: 'hidden',
	},

	introImageWrapper: {
		zIndex: 1,
		paddingLeft: '0px',
	},

	introImage: {
		backgroundImage: `url(${isWebpSupported() && RichieWebp})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: '100%',
		width: '100%',
	},

	introContentWrapper: {
		zIndex: 2,
	},

	logoWrapper: {
		height: 300,
	},

	bootstrapLogo: {
		height: '100%',
		backgroundImage: `url(${Blue})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	storybookLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? StorybookLogoWebp : StorybookLogo})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	formikLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? Blue : Blue})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	apexLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? Blue : Blue})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},
	// stylelint-enable
});

const HomeInformation = () => {
	const classes = useStyles();

	const navigate = useNavigate();
	const { user, isActivePlan, setUser, setVerified } = useContext(AuthContext);
	const [modalState, setModalState] = useState(false);
	const [registerModalState, setRegisterModalState] = useState(false);
	const [uploadModalState, setUploadModalState] = useState(false);
	const [earnModalState, setEarnModalState] = useState(false);
	const { mobileDesign } = useContext(ThemeContext);

	const handleEarnModal = () => {
		setEarnModalState(!earnModalState);
	};

	const handleUploadModal = () => {
		setUploadModalState(!uploadModalState);
	};

	const handleRegisterModal = () => {
		setRegisterModalState(!registerModalState);
	};
	const handleModal = () => {
		setModalState(!modalState);
	};
	const handleEmail = () => {
		if (user) {
			console.log(user);
			sendEmailVerification(user)
				.then(() => {
					// Handle success, maybe display a message to the user
					showNotification(
						'Success',
						'Please check your inbox for the verification email',
						'success',
					);
					if (setUser) {
						setUser(null);
						setVerified(false);
					}
					navigate('/submit-email');
				})
				.catch((error) => {
					// Handle errors, such as network issues or invalid user state
					showNotification('Error', 'Please sign in to a valid account to send an email');
				});
		} else {
			showNotification('Error', 'Please sign in to a valid account to send an email');
			// Optionally, handle the UI feedback about no user being logged in
		}
	};

	const items = [
		{
			src: '',
			altText: 'Slide 1',
			captionHeader: 'Slide 1 title',
			captionText: 'Slide 1 text',
		},
		{
			src: '',
			altText: 'Slide 2',
			captionHeader: 'Slide 2 title',
			captionText: 'Slide 2 text',
		},
		{
			src: '',
			altText: 'Slide 3',
			captionHeader: 'Slide 3 title',
			captionText: 'Slide 3 text',
		},
	];
	type Rotate = {
		rotateX: number;
		rotateY: number;
	};

	const [rotate, setRotate] = useState<Rotate>({ rotateX: 0, rotateY: 0 });

	// Handle mouse movement inside the div
	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const {
			offsetWidth: width,
			offsetHeight: height,
			offsetLeft: left,
			offsetTop: top,
		} = e.currentTarget;
		const x = e.clientX - left;
		const y = e.clientY - top;

		// Calculate rotation proportional to mouse position
		const rotateX = (y / height - 0.5) * 35; // Max 30 degrees on the Y-axis
		const rotateY = (x / width - 0.5) * -35; // Max 30 degrees on the X-axis

		setRotate({ rotateX, rotateY });
	};
	const [isHovered, setIsHovered] = useState(false);
	const [isImgHovered, setIsImgHovered] = useState(false);
	const [isTxtHovered, setIsTxtHovered] = useState(false);

	// Reset rotation on mouse leave
	const handleMouseLeave = () => {
		setRotate({ rotateX: 0, rotateY: 0 });
	};
	return (
		<div>
			<PageWrapper title='Melody Rights'>
				<Page>
					<div id='intro' className='scroll-margin' style={{ scrollBehavior: 'smooth' }}>
						<div
							className={classNames(
								'row',

								classes.intro,
								'd-flex justify-content-center',
							)}>
							<div
								style={{
									transform: `rotateX(${rotate.rotateX}deg) rotateY(${rotate.rotateY}deg)`,
									transition: 'transform 0.1s ease-out',
								}}
								onMouseMove={handleMouseMove}
								onMouseLeave={handleMouseLeave}
								className={classNames(
									'col-lg-6',
									'col-6',
									classes.introImageWrapper,
								)}>
								<img
									src={Blue}
									style={
										mobileDesign
											? { width: '300px', marginLeft: '-60px' }
											: { width: '500px' }
									}
								/>
							</div>

							<div
								className={classNames(
									'col-lg-6',
									'col-12',
									'd-flex',

									'justify-content-center',
									'mt-5',
									classes.introContentWrapper,
								)}>
								<div>
									WELCOME TO
									<br />
									<h1>
										{' '}
										<span
											className='h1 mb-0 fw-bold mx-1'
											style={{ fontSize: '50px' }}>
											MELODY RIGHTS
										</span>
									</h1>
									<p className='display-6 mb-4'>
										A new standard in music distribution
									</p>
									<p className='h2 mb-5'>
										Your <span className='text-info'>music </span>
										Your <span className='text-info'>royalties </span>
										Our <span className='text-info'>expertise</span>
									</p>
									{user === null ? (
										<Button
											color='success'
											className='w-100'
											size='lg'
											shadow='lg'
											icon='Class'
											tag='a'
											to='/submit-email'>
											Sign up or login
										</Button>
									) : user != null && user.emailVerified && !isActivePlan ? (
										<Button
											color='primary'
											className='w-100'
											size='lg'
											shadow='lg'
											icon='Class'
											tag='a'
											to='/view-plans'>
											Activate plan
										</Button>
									) : user != null && !user.emailVerified && !isActivePlan ? (
										<Button
											color='success'
											className='w-100'
											size='lg'
											shadow='lg'
											icon='Class'
											tag='a'
											onClick={handleModal}>
											Send verification email
										</Button>
									) : (
										<Button
											color='success'
											size='lg'
											className='w-100'
											shadow='lg'
											icon='Class'
											tag='a'
											onClick={() => {
												startTransition(() => {
													navigate('/dashboard');
												});
											}}>
											Visit dashboard
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='d-flex justify-content-center'></div>

					<div
						style={{
							transform: isHovered ? 'scale(1.02)' : 'scale(1)', // Scale up on hover
							boxShadow: isHovered
								? '0 0 15px 5px rgba(81, 108, 250, 0.5)' // Glow effect on hover
								: '', // Subtle shadow when not hovered
							padding: '20px',
							borderRadius: '30px', // Rounded corners for visual effect
							transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
						}}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
						className={classNames(
							'row',

							classes.intro,
							'd-flex justify-content-center  rounded ',
						)}>
						<div
							className={classNames(
								'col-lg-6',
								'col-6',

								classes.introImageWrapper,
							)}>
							<div
								style={
									mobileDesign
										? { marginTop: '50px', marginLeft: '-80px' }
										: { marginTop: '50px' }
								}>
								<h1>
									{' '}
									<span
										className='h1 mb-0 fw-bold mx-1'
										style={
											mobileDesign
												? { fontSize: '50px', marginLeft: '-170px' }
												: { fontSize: '50px' }
										}>
										<span>Your music,</span>
										<span className='text-info'> everywhere </span>
									</span>
								</h1>
								<p className='h3 mb-5'>
									At Melody Rights we remove the admin side of music distribution.
									Royalty registrations, account creating and endless form filling
									shouldn’t take up your time. Music doesn’t come from paperwork,
									let us handle the details. With Melody Rights your music goes
									further while you stay creative.{' '}
								</p>
							</div>
						</div>
						<div
							className={classNames(
								'col-lg-6',
								'col-12',
								'd-flex',
								'justify-content-center align-items-center',
								'mt-5',
								classes.introContentWrapper,
							)}>
							<div className='mb-5'>
								<p className='h3 mb-5'>
									Our platform simplifies the world of music
								</p>

								<div className='row'>
									<a
										href='https://www.youtube.com/watch?v=rgORDJvnoBs'
										target='_blank'
										rel='noopener noreferrer'
										className='w-100'>
										<Button color='info' className='w-100'>
											Visit tutorial
										</Button>
									</a>
								</div>
							</div>
						</div>
						<div className='d-flex justify-content-center mt-2'>
							<br />
							<div className='col lg-12 d-flex justify-content-center'>
								<div className='row lg-4 my-3'>
									<p className='h3'>
										Interested in finding out more? Visit our FAQ below
									</p>
								</div>
							</div>
							<div className='row'></div>
						</div>

						<Button
							color='primary'
							size='lg'
							shadow='lg'
							isOutline
							icon='Class'
							tag='a'
							to='/about-us'
							style={{ width: '200px' }}>
							Visit FAQ
						</Button>
					</div>
					<div
						id='intro'
						className='scroll-margin my-5'
						style={{ scrollBehavior: 'smooth' }}>
						<div
							style={{
								transform: isTxtHovered ? 'scale(1.02)' : 'scale(1)', // Scale up on hover
								boxShadow: isTxtHovered
									? '0 0 15px 5px rgba(81, 108, 250, 0.5)' // Glow effect on hover
									: '', // Subtle shadow when not hovered
								padding: '20px',
								borderRadius: '30px', // Rounded corners for visual effect
								transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
							}}
							onMouseEnter={() => setIsTxtHovered(true)}
							onMouseLeave={() => setIsTxtHovered(false)}
							className={classNames(
								'row',

								classes.intro,
								'd-flex justify-content-center my-3',
							)}>
							<div
								className={classNames(
									'col-lg-6',
									'col-6',
									classes.introImageWrapper,
								)}>
								<video
									src={RichieWebp}
									style={{ width: '100%' }}
									autoPlay
									loop
									muted
									playsInline
								/>
							</div>
							<div
								className={classNames(
									'col-lg-6',
									'col-12',
									'd-flex',

									'justify-content-center',
									'mt-5',
									classes.introContentWrapper,
								)}>
								<div>
									<div className='col-12 shadow-3d-container mb-2'>
										<strong>What do we do?</strong>
										<p>
											We provide you with a portal to the most widespread
											music distribution system in the world.
										</p>
										<strong>
											Where will we track your music being played or sold?
										</strong>
										<p>
											Any TV media, Youtube, in licenced public performances,
											any Radio broadcast, CDs and digital downloads, sales of
											sheet music and musical scores, any public establishment
											such as bars or retail stores, any physical item such as
											toys and novelty items, writers licencing deals, direct
											sales of social music sites and ad revenue, sales on
											gaming marketplace, sales on multiple stock sites. Oh,
											and did we mention any Digital streaming platform such
											as Spotify, Amazon Music, Apple Music, Pandora, Deezer,
											Youtube music.... and so on?
										</p>
										<strong>
											But what if your music isn't played anywhere?
										</strong>
										<p>
											The platforms we distribute to have been carefully
											chosen to not only track your music, but also provide
											all sorts of media creators access to purchase or use
											your music. If your music is played, you get payed.
										</p>
									</div>
								</div>
							</div>
							{user === null ? (
								<Button
									color='success'
									className='w-100'
									size='lg'
									shadow='lg'
									icon='Class'
									tag='a'
									to='/submit-email'>
									Get started
								</Button>
							) : user != null && user.emailVerified && !isActivePlan ? (
								<Button
									color='primary'
									className='w-100'
									size='lg'
									shadow='lg'
									icon='Class'
									tag='a'
									to='/view-plans'>
									Activate plan
								</Button>
							) : user != null && !user.emailVerified && !isActivePlan ? (
								<Button
									color='success'
									className='w-100'
									size='lg'
									shadow='lg'
									icon='Class'
									tag='a'
									onClick={handleModal}>
									Send verification email
								</Button>
							) : (
								<Button
									color='success'
									size='lg'
									shadow='lg'
									icon='Class'
									tag='a'
									onClick={() => {
										startTransition(() => {
											navigate('/dashboard');
										});
									}}>
									Visit dashboard
								</Button>
							)}
						</div>
					</div>
					<div className='d-flex justify-content-center w-100'>
						<img
							src={MRComparison}
							style={
								mobileDesign
									? { width: '100%', height: '300px' }
									: {
											width: '70%',
											transform: isImgHovered ? 'scale(1.02)' : 'scale(1)', // Scale up on hover
											boxShadow: isImgHovered
												? '0 0 15px 5px rgba(81, 108, 250, 0.3)' // Glow effect on hover
												: '', // Subtle shadow when not hovered
											padding: '20px',
											borderRadius: '30px', // Rounded corners for visual effect
											transition: 'all 0.3s ease-in-out', // Smooth transition for scaling and glow
										}
							}
							onMouseEnter={() => setIsImgHovered(true)}
							onMouseLeave={() => setIsImgHovered(false)}
							className='rounded'
						/>
					</div>
					<div style={{ marginBottom: '45px' }} />
					<div className='d-flex justify-content-center mt-5'>
						<br />
						<div className='col lg-12 d-flex justify-content-center'>
							<div className='row lg-4 my-3'>
								<p className='h3 mb-3'>Have a question? Contact us</p>
							</div>
						</div>
						<div className='row'></div>
					</div>
					<div className='d-flex justify-content-center'>
						<Button
							color='primary'
							size='lg'
							shadow='lg'
							isOutline
							icon='Class'
							tag='a'
							to='/contact'
							style={{ width: '200px' }}>
							Contact us
						</Button>
					</div>
				</Page>
				<Modal
					isOpen={modalState}
					setIsOpen={handleModal}
					titleId='exampleModalLabel'
					isStaticBackdrop={false}
					isScrollable={false}
					isCentered
					fullScreen={false}
					isAnimation>
					<ModalHeader setIsOpen={handleModal}>
						<ModalTitle id='exampleModalLabel'>
							<strong>File Section</strong>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='col-12 shadow-3d-container'>
							<Alert color='danger' icon='warning'>
								Continuing with the email verification process will log you out.
							</Alert>

							<p>
								Once the link in the email has been clicked, continue to log back in
							</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color='success' onClick={handleEmail}>
							Send verification Email
						</Button>
					</ModalFooter>
				</Modal>
				<Modal
					isOpen={registerModalState}
					setIsOpen={handleRegisterModal}
					titleId='exampleModalLabel'
					isStaticBackdrop={false}
					isScrollable={false}
					isCentered
					size={'lg'}
					fullScreen={false}
					isAnimation>
					<ModalHeader setIsOpen={handleRegisterModal}>
						<ModalTitle id='exampleModalLabel'>
							<strong>Register</strong>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='container'>
							<div className='row'>
								<div className='col-md-4'>
									<p className='h4 '>
										<span className='text-info mx-4'>1.</span>
										Create an account
									</p>
								</div>
								<div className='col-md-4'>
									<p className='h4 '>
										<span className='text-info mx-4'>2.</span>
										Verify your email
									</p>
								</div>
								<div className='col-md-4'>
									<p className='h4 '>
										<span className='text-info mx-4'>3.</span>
										Activate your plan
									</p>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter className='d-flex justify-content-center'>
						Your email must be verified to activate a plan
					</ModalFooter>
				</Modal>
				<Modal
					isOpen={uploadModalState}
					setIsOpen={handleUploadModal}
					titleId='exampleModalLabel'
					isStaticBackdrop={false}
					isScrollable={false}
					isCentered
					size={'lg'}
					fullScreen={false}
					isAnimation>
					<ModalHeader setIsOpen={handleUploadModal}>
						<ModalTitle id='exampleModalLabel'>
							<strong>Upload music</strong>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='container'>
							<div className='row'>
								<div className='col-md-4'>
									<p className='h4 '>
										<span className='text-info'>1.</span>
										Prepare your files
									</p>
								</div>
								<div className='col-md-4'>
									<p className='h4 '>
										<span className='text-info mx-1'>2.</span>
										Use our portal
									</p>
								</div>
								<div className='col-md-4'>
									<p className='h4 '>
										<span className='text-info'>3.</span>
										Wait for the files to upload
									</p>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter className='d-flex justify-content-center'>
						You must have an active plan to upload
					</ModalFooter>
				</Modal>
				<Modal
					isOpen={earnModalState}
					setIsOpen={handleEarnModal}
					titleId='exampleModalLabel'
					isStaticBackdrop={false}
					isScrollable={false}
					isCentered
					size={'lg'}
					fullScreen={false}
					isAnimation>
					<ModalHeader setIsOpen={handleEarnModal}>
						<ModalTitle id='exampleModalLabel'>
							<strong>Earn</strong>
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='container'>
							<p className='h4'>
								We issue quarterly payments to our clients, aligning with industry
								standards and reflecting the schedule on which we receive payment
								reports.{' '}
							</p>
							<div className='row my-3 mt-5'>
								<div className='col-md-3'>
									<p className='h4 '>
										<span className='text-info'>Q1</span> - January
									</p>
								</div>
								<div className='col-md-3'>
									<p className='h4 '>
										<span className='text-info mx-1'>Q2</span> - April
									</p>
								</div>
								<div className='col-md-3'>
									<p className='h4 '>
										<span className='text-info'>Q3</span> - August
									</p>
								</div>
								<div className='col-md-3'>
									<p className='h4 '>
										<span className='text-info'>Q4</span> - December
									</p>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter className='d-flex justify-content-center'>
						You must have an active plan to receive payments
					</ModalFooter>
				</Modal>
			</PageWrapper>
		</div>
	);
};

export default HomeInformation;
