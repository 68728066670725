import React, { useState } from 'react';
import { useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import firebase from '../../../Firebase/firebase';
import Card, {
	CardBody,
	CardTitle,
	CardSubTitle,
	CardLabel,
	CardFooter,
} from '../../bootstrap/Card';
import { useParams } from 'react-router-dom';
import Button from '../../bootstrap/Button';
import { useNavigate } from 'react-router-dom';

interface ViewASubmissionProps {
	submissionId: string;
}
const ViewASubmission = () => {
	const { id } = useParams();
	const { auth, db } = firebase;
	const [data, setData] = useState<any>(null);
	const navigate = useNavigate();
	useEffect(() => {
		const fetchDocument = async () => {
			if (id != undefined) {
				const docRef = doc(db, 'submissions', id);
				try {
					const docSnap = await getDoc(docRef);
					if (docSnap.exists()) {
						setData(docSnap.data()); // Set the document data if found
					} else {
						console.log('No such document!');

						setData(null);
					}
				} catch (error) {
					console.error('Error fetching document:', error);
					// Set error if something goes wrong
				}
			}
		};

		if (id) {
			// Ensuring the docId is not null or undefined
			fetchDocument();
		}
	}, [id]); // Dependency array to trigger useEffect when docId changes
	if (!id || !data) return <div>Loading or ID is missing...</div>;
	return (
		<>
			<div style={{ width: '100%' }}>
				<div className='d-flex justify-content-center align-items-center my-5'>
					<div
						className='d-flex justify-content-center align-items-center my-5'
						style={{ width: '100%' }}>
						<Card>
							<Button
								color='info'
								isLink
								icon='ArrowBack'
								onClick={() => navigate(-1)}>
								Back to my submissions
							</Button>

							<CardBody>
								<CardTitle>Submission {id}</CardTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Genre Selected:{' '}
									{data.instrumental !== undefined
										? data.genre.toString()
										: 'No genre selected please contact to fix'}
								</CardSubTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Number Of Files Uploaded: {data.numberOfFiles}
								</CardSubTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Explicity:{' '}
									{data.explicity !== undefined
										? data.explicity.toString()
										: 'Loading...'}
								</CardSubTitle>

								<CardSubTitle style={{ marginBottom: '20px', marginTop: '20px' }}>
									Instrumental:{' '}
									{data.instrumental !== undefined
										? data.explicity.toString()
										: 'Loading...'}
								</CardSubTitle>

								<CardSubTitle>
									Sites distributed:
									{data?.sitesToDistribute &&
									data.sitesToDistribute.length > 0 ? (
										<ul>
											{data.sitesToDistribute.map(
												(site: any, index: number) => (
													<li key={index}>
														{/* Replace this with your custom component if you have one */}
														{site}
													</li>
												),
											)}
										</ul>
									) : (
										<p>No sites distributed.</p>
									)}
								</CardSubTitle>
								<CardSubTitle>
									File names uploaded:
									{data?.fileNames && data.fileNames.length > 0 ? (
										<ul>
											{data.fileNames.map((name: any, index: number) => (
												<li key={index}>
													{/* Replace this with your custom component if you have one */}
													{name}
												</li>
											))}
										</ul>
									) : (
										<p>No sites distributed.</p>
									)}
								</CardSubTitle>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewASubmission;
