import React, { FC, useState, ChangeEvent, useRef, useEffect } from 'react';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import Avatar from '../../Avatar';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import User1Img from '../../../assets/img/wanna/wanna2.png';
import { set } from 'date-fns';
import { number } from 'prop-types';

interface UploadedFile {
	id: number;
	name: string;
	type: string;
	count: number;
	date: Date;
}
type TTabs = 'Files' | 'Artwork';
interface ITabs {
	[key: string]: TTabs;
}
interface UploadProps {
	setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
	numberOfFilesToUpload: number;
}
const storage = getStorage();

const Upload: React.FC<UploadProps> = ({ setUploadedFiles, numberOfFilesToUpload }) => {
	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const [numberOfFilesAccepted, setNumberOfFilesAccepted] = useState(false);
	useEffect(() => {
		if (numberOfFilesAccepted) {
			console.log('Files accepted and processed');
			// Additional operations that depend on files being accepted
		}
	}, [numberOfFilesAccepted]); // This effect runs whenever numberOfFilesAccepted changes

	const [refresh, setRefresh] = useState(false);
	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (!files) return; // Early exit if no files

		const filesArray = Array.from(files);

		// Clear any previously uploaded files first
		setUploadedFiles([]); // Assuming you want to reset every time this function runs

		if (filesArray.length > numberOfFilesToUpload) {
			alert(`Please upload no more than ${numberOfFilesToUpload} files`);
			return; // Stop further processing if the file count does not match
		}

		// If the file count is correct, proceed to accept files
		console.log('it is being set to true');
		// Directly using true since we know files are accepted at this point
		setNumberOfFilesAccepted(true);

		// Process the files since we know the count is correct
		const uploadedFiles = filesArray.map((file, index) => ({
			id: index + 1,
			name: file.name,
			type: 'file',
			count: file.size,
			date: new Date(),
		}));

		console.log('Uploaded files:', uploadedFiles);

		// Update the uploadedFiles state in the parent component
		setUploadedFiles(filesArray);
		setFilesToUpload(filesArray);
	};

	const upload = async () => {
		if (filesToUpload != null) {
			for (let i = 0; i < filesToUpload.length; i++) {
				const file = filesToUpload[i];
				const storageRef = ref(storage, file.name); // Create a reference to the file
				console.log(file);
				try {
					// Upload file to Firebase Storage
					await uploadBytes(storageRef, file);
					// Now you can handle metadata or other operations if needed
				} catch (error) {
					console.error('Error uploading file:', error);
					// Handle error
				}
			}
		} else {
			console.log('error is null');
		}
	};
	const TABS: ITabs = {
		FILES: 'Files',
		ARTWORK: 'Artwork',
	};
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [activeTab, setActiveTab] = useState(TABS.FILES);
	const deleteImage = () => {
		// Clear the input value to reset it

		// Clear the uploadedFiles state in the parent component
		setUploadedFiles([]);
	};

	return (
		<div>
			<Card>
				<CardBody>
					{activeTab === TABS.FILES && (
						<>
							<div className='row g-4 align-items-center'>
								<div className='col-xl-auto' />
								<div className='col-xl'>
									<div className='row g-4'>
										<div className='col-auto'>
											<Input
												type='file'
												multiple
												autoComplete='off'
												// ariaabel="Choose folder"
												// @ts-ignore
												webkitdirectory='true'
												onMouseDown={(e) => e.preventDefault()}
												onChange={handleFileChange}
											/>
										</div>
										<div className='col-auto'>
											<Button
												color='dark'
												isLight
												icon='Delete'
												onClick={deleteImage}>
												Delete Files
											</Button>
										</div>

										<div className='col-12'>
											<p className='lead text-muted'>
												All uploaded tracks will appear below
											</p>
										</div>
									</div>
								</div>
							</div>
						</>
					)}

					{activeTab === TABS.ARTWORK && <>hello</>}
				</CardBody>
			</Card>
		</div>
	);
};

export default Upload;
