import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import dayjs from 'dayjs';
import { useTour } from '@reactour/tour';
import ProfilePageHeader from '../ProfilePageHeader';
import { doc, getDoc } from 'firebase/firestore';
import showNotification from '../../extras/showNotification';
import firebase from '../../../Firebase/firebase';
import Icon from '../../icon/Icon';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardBody } from '../../bootstrap/Card';
import StripeContainer from './CheckoutForm';
import { useNavigate } from 'react-router';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
const PricingPlan = () => {
	/**
	 * Setup navigation
	 *
	 * And navigate functions
	 */
	const navigate = useNavigate();
	const navigateSilverPage = () => {
		navigate('/silverplan');
	};
	const navigateGoldPage = () => {
		navigate('/goldplan');
	};
	const navigateEnterprisePage = () => {
		navigate('/enterpriseplan');
	};

	const { auth, db } = firebase;

	useEffect(() => {
		const fetchUserData = async () => {
			console.log('hi');
			try {
				// Get the current signed-in user
				const user = auth.currentUser;

				if (user) {
					// Reference to the Firestore document where `userId` equals the signed-in user's ID
					const userRef = doc(db, 'users', user.uid); // Assuming user.uid is the ID stored in Firestore

					// Fetch the document from Firestore
					const userDoc = await getDoc(userRef);

					if (userDoc.exists()) {
						// Access the isActivePlan field (and any other fields)
						const userData = userDoc.data();
						console.log('it fuckinada');
						if (userData.activePlan === true) {
							navigate('/dashboard');
							showNotification('error', 'you are already on a plan', 'danger');
						} else if (userData.activePlan === false) {
							console.log('but its false?');
							showNotification(
								'Welcome!',
								'Select a plan, and get started',
								'success',
							);
						}
					} else {
						console.error('No user document found');
					}
				} else {
					console.error('No user signed in');
				}
			} catch (error) {
				console.error('Error fetching user data:', error);
				showNotification('Error fetching user data', 'error', 'danger');
			} finally {
			}
		};

		// Call the fetchUserData function when the component renders
		fetchUserData();
	}, []);

	return (
		<div>
			<PageWrapper>
				<SubHeader>
					<SubHeaderLeft>
						<Button color='info' isLink icon='ArrowBack' onClick={() => navigate('/')}>
							Back to home
						</Button>
						<SubheaderSeparator />

						<span className='text-muted'>View plans</span>
					</SubHeaderLeft>
				</SubHeader>
				<Card className='d-flex justify-content-center align-items-center my-5'>
					<CardBody>
						<div className='row d-flex justify-content-center align-items-center my-2'>
							<div className='col-md-3'>
								<Card>
									<CardBody>
										<div className='row pt-5 g-4 text-center'>
											<div className='col-12'>
												<Icon
													icon='CustomRocketLaunch'
													size='7x'
													color='info'
												/>
											</div>
											<div className='col-12'>
												<h2>Artist</h2>
											</div>
											<div className='col-12'>
												<h3 className='display-1 fw-bold'>
													<span className='display-4 fw-bold'>$</span>5
													<span className='display-6'>/mo</span>
												</h3>
											</div>
											<div className='col-12'>
												<div className='lead'>
													<Icon icon='Done Outline' color='success' /> 5
													tracks a month
												</div>
												<div className='lead'>
													<Icon icon='Done Outline' color='success' /> 50
													/ 50 split for Artist
												</div>
												<div className='lead '>
													<Icon icon='Close Outline' color='success' />{' '}
													Gaming marketplaces
												</div>
											</div>
											<div className='col-12'>
												<p>Click below to get started</p>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													isLight
													className='w-100 py-3 text-uppercase'
													size='lg'
													onClick={navigateSilverPage}>
													Select Plan
												</Button>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className='col-md-3'>
								<Card>
									<CardBody>
										<div className='row pt-5 g-4 text-center'>
											<div className='col-12'>
												<Icon
													icon='Maps Home Work'
													size='7x'
													color='success'
												/>
											</div>
											<div className='col-12'>
												<h2>Producer</h2>
											</div>
											<div className='col-12'>
												<h3 className='display-1 fw-bold'>
													<span className='display-4 fw-bold'>$</span>10
													<span className='display-6'>/mo</span>
												</h3>
											</div>
											<div className='col-12'>
												<div className='lead'>
													<Icon icon='Done Outline' color='success' /> 10
													tracks a month
												</div>
												<div className='lead'>
													<Icon icon='Done Outline' color='success' /> 40
													/ 60 Artist keeps 60%
												</div>
												<div className='lead '>
													<Icon icon='Done Outline' color='success' />{' '}
													Every endpoint
												</div>
											</div>
											<div className='col-12'>
												<p>Click below to get started</p>
											</div>
											<div className='col-12'>
												<Button
													color='success'
													className='w-100 py-3 text-uppercase'
													size='lg'
													onClick={navigateGoldPage}>
													Select Plan
												</Button>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
							<div className='col-md-3'>
								<Card>
									<CardBody>
										<div className='row pt-5 g-4 text-center'>
											<div className='col-12'>
												<Icon icon='Label' size='7x' color='info' />
											</div>
											<div className='col-12'>
												<h2>Label</h2>
											</div>
											<div className='col-12'>
												<h3 className='display-1 fw-bold'>
													<span className='display-4 fw-bold'>$</span>50
													<span className='display-6'>/mo</span>
												</h3>
											</div>
											<div className='col-12'>
												<div className='lead'>
													<Icon icon='Done Outline' color='success' /> 50
													tracks a month
												</div>
												<div className='lead'>
													<Icon icon='Done Outline' color='success' /> 70
													/ 30 Artist Keeps 70%
												</div>
												<div className='lead '>
													<Icon icon='Done Outline' color='success' />{' '}
													Every endpoint
												</div>
											</div>
											<div className='col-12'>
												<p>Click below to get started</p>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													isLight
													className='w-100 py-3 text-uppercase'
													size='lg'
													onClick={navigateEnterprisePage}>
													Select Plan
												</Button>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</CardBody>
				</Card>
			</PageWrapper>
		</div>
	);
};

export default PricingPlan;
