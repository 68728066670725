import React, { useEffect, useState, useContext, useTransition, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, sendEmailVerification, reload, signOut } from 'firebase/auth';
import firebase from '../../Firebase/firebase';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import HomeInformation from './HomeInformation';
import PricingPlan from './payment/PricingPlans';
import AuthContext from '../../contexts/authContext';
import Card, { CardBody, CardTitle } from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import Alert from '../bootstrap/Alert';
import showNotification from '../extras/showNotification';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import OnlySubheader from '../../pages/presentation/page-layouts/OnlySubheader';
import ThemeContext from '../../contexts/themeContext';
import DisplayPricingPlans from './DisplayPricingPlans';
import CompetitorImage from '../../assets/img/Melody Rights Competitors Image.png';
import MRLogo from '../../assets/img/blue 2.png';
import Carousel from '../bootstrap/Carousel';

const CrmDashboard = () => {
	const { user, setUser, isVerified, setVerified, setActivePlan, isActivePlan } =
		useContext(AuthContext);
	const [modalState, setModalState] = useState(false);
	const [signUpmodalState, setsignUpModalState] = useState(false);
	const handleModal = () => {
		setModalState(!modalState);
	};
	const handleVerificationModal = () => {
		setVerificationModal(!verificationModal);
	};
	const handleSignUpModal = () => {
		setsignUpModalState(!signUpmodalState);
	};
	const navigate = useNavigate();
	const { auth, db } = firebase;
	const [isPending, startTransition] = useTransition();
	const handleNav = () => {
		startTransition(() => {
			navigate('/submit-email');
		});
	};
	const handleEmail = () => {
		if (user) {
			console.log(user);
			sendEmailVerification(user)
				.then(() => {
					// Handle success, maybe display a message to the user
					showNotification(
						'Success',
						'Please check your inbox for the verification email',
					);
					if (setUser) {
						setUser(null);
						setVerified(false);
					}
					navigate('/submit-email');
				})
				.catch((error) => {
					// Handle errors, such as network issues or invalid user state
					showNotification('Error', 'Please sign in to a valid account to send an email');
				});
		} else {
			showNotification('Error', 'Please sign in to a valid account to send an email');
			// Optionally, handle the UI feedback about no user being logged in
		}
	};
	const handleNavigate = () => {
		navigate('/view-plans');
	};

	const [verificationModal, setVerificationModal] = useState(false);

	const {
		setAsideStatus,
		setLeftMenuStatus,
		setRightMenuStatus,
		setDarkModeStatus,
		setFullScreenStatus,
	} = useContext(ThemeContext);

	useEffect(() => {
		setAsideStatus(false); // Reset sidebar to closed
		setLeftMenuStatus(false); // Reset left menu to hidden
		setRightMenuStatus(false); // Reset right menu to hidden
		setDarkModeStatus(false); // Reset to light mode, if necessary
		setFullScreenStatus(false);
	}, [
		setAsideStatus,
		setLeftMenuStatus,
		setRightMenuStatus,
		setDarkModeStatus,
		setFullScreenStatus,
	]);
	const items = [
		{
			src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
			altText: 'Slide 1',
			captionHeader: 'Slide 1 title',
			captionText: 'Slide 1 text',
		},
		{
			src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
			altText: 'Slide 2',
			captionHeader: 'Slide 2 title',
			captionText: 'Slide 2 text',
		},
		{
			src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
			altText: 'Slide 3',
			captionHeader: 'Slide 3 title',
			captionText: 'Slide 3 text',
		},
	];

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
			console.log(firebaseUser);
			if (firebaseUser) {
				// If a user is signed in
				if (setUser) {
					setUser(firebaseUser);
				}
				if (firebaseUser && !firebaseUser.emailVerified) {
					setTimeout(() => {
						setVerificationModal(true);
					}, 4000);
				}

				const userDocRef = doc(db, 'users', firebaseUser.uid);

				if (firebaseUser.emailVerified) {
					try {
						const userDoc = await getDoc(userDocRef);
						if (userDoc.exists()) {
							const userData = userDoc.data();
							// Set active plan from the user's document
							setActivePlan(userData.activePlan);
						} else {
							console.log('No such document!');
							setActivePlan(false); // Handle case where there is no document for the user
						}
					} catch (error) {
						console.error('Error fetching user data:', error);
						setActivePlan(false); // Handle potential errors, such as permissions issues
					}
				}

				// Optionally fetch and set additional user data here
			} else {
				// No user is signed in
				if (setUser) {
					setUser(null);
				}

				setActivePlan(false);
			}
		});

		// Cleanup the subscription
		return () => unsubscribe();
	}, []);
	const handleLogout = async () => {
		if (setUser) {
			setUser(null);
		}
		console.log('logges');
		setVerified(false);
		setActivePlan(false);
		await signOut(auth);
		navigate('/submit-email');
	};

	auth.onAuthStateChanged((user) => {
		if (user) {
			console.log('Authenticated user:', user);
		} else {
			console.log('No user signed in');
		}
	});

	const style: CSSProperties = {
		// Correctly type the style object
		height: '100vh', // Example height, adjust based on your content
		overflowY: 'scroll',
		scrollBehavior: 'smooth',
	};

	return (
		<PageWrapper title='Melody Rights'>
			<SubHeader>
				<SubHeaderLeft>
					{user != null ? (
						<Button
							color='success'
							size='sm'
							shadow='lg'
							icon='Class'
							tag='a'
							onClick={handleLogout}>
							Logout
						</Button>
					) : (
						<Button
							color='success'
							size='sm'
							shadow='lg'
							icon='Class'
							tag='a'
							onClick={() => {
								navigate('submit-email');
							}}>
							Sign up or login
						</Button>
					)}

					<SubheaderSeparator />
					<Button
						color='success'
						size='sm'
						shadow='lg'
						icon='Class'
						tag='a'
						onClick={() => {
							navigate('/about-us');
						}}>
						Visit FAQ
					</Button>
					<SubheaderSeparator />
					<Button
						color='success'
						size='sm'
						shadow='lg'
						icon='Class'
						tag='a'
						onClick={() => {
							navigate('/contact');
						}}>
						Contact
					</Button>

					<span className='text-muted'>{user != null ? user.email : ''}</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					{user?.emailVerified && !isActivePlan ? (
						<CardTitle className='text-muted'>
							<Button />
							<Button color='success' onClick={handleNavigate}>
								View plans page
							</Button>
						</CardTitle>
					) : user != null && !isActivePlan ? (
						<CardTitle className='d-flex justify-content-center align-items-center'>
							Email verification required <Button />
							<Button color='success' onClick={handleModal}>
								Send verification Email
							</Button>
						</CardTitle>
					) : user != null && isActivePlan ? (
						<CardTitle className='d-flex justify-content-center align-items-center'>
							{' '}
							<Button />
							<Button
								color='success'
								onClick={() => {
									startTransition(() => {
										navigate('/dashboard');
									});
								}}>
								View dashboard
							</Button>
						</CardTitle>
					) : (
						<></>
					)}
				</SubHeaderRight>
			</SubHeader>
			{/* {user?.emailVerified ? (
				<Card>
					<CardBody className='d-flex justify-content-center align-items-center'>
						<CardTitle>
							Your email has been verified, welcome to Melody Rights <Button />
							<Button color='success' onClick={handleNavigate}>
								View plans page
							</Button>
						</CardTitle>
					</CardBody>
				</Card>
			) : user != null ? (
				<Card>
					<CardBody>
						<CardTitle className='d-flex justify-content-center align-items-center'>
							Your email is not verified, for access to plans please verify your Email{' '}
							<Button />
							<Button color='success' onClick={handleModal}>
								Send verification Email
							</Button>
						</CardTitle>
					</CardBody>
				</Card>
			) : (
				<></>
			)} */}
			<Page>
				<div className='row my-5' style={{ scrollBehavior: 'smooth' }}>
					<div style={{ marginBottom: '80px', marginTop: '-20px' }} />
					<HomeInformation />

					<div className='my-5'>
						<DisplayPricingPlans />
					</div>
					<div className='col-lg-8' />
					<div className='col-lg-4' />

					<div className='col-lg-6' />
					<div className='col-lg-6' />
				</div>
			</Page>
			<Modal
				isOpen={modalState}
				setIsOpen={handleModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Verify your email</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Alert color='danger' icon='warning'>
							Continuing with the email verification process will log you out.
						</Alert>

						<p>Once the link in the email has been clicked, continue to log back in</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='success' onClick={handleEmail}>
						Send verification Email
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={verificationModal}
				setIsOpen={handleVerificationModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleVerificationModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>
							Thanks for signing up! Please veriy your email for access to plans
						</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Alert color='danger' icon='warning'>
							Continuing with the email verification process will log you out.
						</Alert>

						<p>Once the link in the email has been clicked, continue to log back in</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='success' onClick={handleEmail}>
						Send verification Email
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={signUpmodalState}
				setIsOpen={handleSignUpModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={true}
				isScrollable={false}
				isCentered
				size='lg'
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleSignUpModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Welcome to Melody Rights!</strong>
						<img src={MRLogo} style={{ width: '100px', marginLeft: '-30px' }} />
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex justify-content-center'></div>
					<div className='col-12 shadow-3d-container'>
						<strong>What do we do?</strong>
						<p>
							We provide you with a portal to the most widespread music distribution
							system in the world.
						</p>
						<strong>Where will we track your music being played or sold?</strong>
						<p>
							Any TV media, Youtube, in licenced public performances, any Radio
							broadcast, CDs and digital downloads, sales of sheet music and musical
							scores, any public establishment such as bars or retail stores, any
							physical item such as toys and novelty items, writers licencing deals,
							direct sales of social music sites and ad revenue, sales on gaming
							marketplace, sales on multiple stock sites. Oh, and did we mention any
							Digital streaming platform such as Spotify, Amazon Music, Apple Music,
							Pandora, Deezer, Youtube music.... and so on?
						</p>
						<strong>But what if your music isn't played anywhere?</strong>
						<p>
							The platforms we distribute to have been carefully chosen to not only
							track your music, but also provide all sorts of media creators access to
							purchase or use your music. If your music is played, you get payed.
						</p>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex align-items-center justify-content-end'>
					<div className=''>
						<Button color='success' onClick={handleNav} icon='Create'>
							Sign up or login
						</Button>
						<Button
							color='success'
							className='mx-2'
							onClick={() => {
								setsignUpModalState(false);
							}}
							icon='ChromeReaderMode'>
							Continue reading
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default CrmDashboard;
