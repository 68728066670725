import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ApexOptions } from 'apexcharts';
import Checks from '../../bootstrap/forms/Checks';
import Chart from '../../extras/Chart';
import Badge from '../../bootstrap/Badge';
import Button from '../../bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';

interface ICommonTableRowProps {
	id: string | number;
	image: string;
	name: string;
	category: string;
	series: ApexOptions['series'];
	color: string;
	stock: string | number;
	toLink: string;
	store: string;
	selectOnChange: any;
	selectChecked: any;
	selectName: string;
}
const MyCommonTableRow: FC<ICommonTableRowProps> = ({
	id,
	image,
	name,
	category,
	color,
	stock,
	store,
	selectOnChange,
	selectChecked,
	selectName,
	toLink,
}) => {
	const { darkModeStatus } = useDarkMode();

	const naviate = useNavigate;

	const dummyOptions: ApexOptions = {
		colors: [color],
		chart: {
			type: 'line',
			width: 100,
			height: 35,
			sparkline: {
				enabled: true,
			},
		},
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: false,
			},
			x: {
				show: false,
			},
			y: {
				title: {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					formatter(seriesName: string) {
						return '';
					},
				},
			},
		},
		stroke: {
			curve: 'smooth',
			width: 2,
		},
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSTModalOpen, setIsSTModalOpen] = useState(false);

	const handleModal = (name: string) => {
		if (name == 'BMI') {
			toggleModal;
		} else {
			toggleSongTrust;
		}
	};
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};
	const toggleSongTrust = () => {
		setIsModalOpen(!isModalOpen);
	};
	return (
		<tr>
			<th scope='row' aria-label='Check'>
				<Checks
					id={id.toString()}
					name={selectName}
					value={id}
					onChange={selectOnChange}
					checked={selectChecked}
					ariaLabel={selectName}
					style={{ width: '20px', height: '20px' }}
				/>
			</th>
			<th scope='row'>{id}</th>

			<td>
				<div>
					<Button
						onClick={() => {
							handleModal(name);
						}}
						color={selectChecked ? 'danger' : 'light'}
						className={classNames('fw-bold', {
							'link-dark': !darkModeStatus,
							'link-light': darkModeStatus,
						})}>
						{name}
					</Button>

					<div className='text-muted'>
						<small>{category}</small>
					</div>
				</div>
			</td>

			<td className='h5'>
				<Badge
					color={
						(store === 'Stock' && 'danger') ||
						(store === 'Company B' && 'warning') ||
						(store === 'Company C' && 'success') ||
						'info'
					}>
					{store}
				</Badge>
			</td>
			<td />
		</tr>
	);
};

export default MyCommonTableRow;
