import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ApexOptions } from 'apexcharts';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
	uploadBytes,
} from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../bootstrap/Button';
import Checks from '../../bootstrap/forms/Checks';
import tableData from '../../../common/data/dummyProductData';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';
import Icon from '../../icon/Icon';
import Accordion, { AccordionItem } from '../../bootstrap/Accordion';
import showNotification from '../../extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Upload from './Upload';
import BoxedSites from './BoxedSites';
import GenreComponent from './GenreComponent';
import Alert from '../../bootstrap/Alert';
import firebase from '../../../Firebase/firebase'; // Assuming you have initialized your Firebase app and obtained the Firestore instance
import Spinner from '../../bootstrap/Spinner';
import Progress from '../../bootstrap/Progress';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Wizard, { WizardItem } from '../../Wizard';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import AuthContext from '../../../contexts/authContext';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';

interface IValues {
	name: string;
	price: number;
	stock: number;
	category: string;
	image?: string;
}
interface UploadedFile {
	id: number;
	name: string;
	type: string;
	count: number;
	date: Date;
}
const validate = (values: IValues) => {
	const errors = {
		name: '',
		stock: '',
		category: '',
	};

	if (!values.name) {
		errors.name = 'Required';
	} else if (values.name.length < 3) {
		errors.name = 'Must be 3 characters or more';
	} else if (values.name.length > 20) {
		errors.name = 'Must be 20 characters or less';
	}

	if (!values.stock) {
		errors.stock = 'Required';
	}

	if (!values.category) {
		errors.category = 'Required';
	} else if (values.category.length < 3) {
		errors.category = 'Must be 3 characters or more';
	} else if (values.category.length > 20) {
		errors.category = 'Must be 20 characters or less';
	}

	return errors;
};

type TTabs = 'Files' | 'Exclude' | 'Confirm' | 'Genre';
interface ITabs {
	[key: string]: TTabs;
}

const UploadPage = () => {
	const { darkModeStatus } = useDarkMode();
	const { db, auth } = firebase;
	const { id } = useParams();
	const navigate = useNavigate();
	const { user, isVerified, isActivePlan } = useContext(AuthContext);
	// @ts-ignore
	const itemData = tableData.filter((item) => {
		return item.id != null && id != null && item.id.toString() === id.toString();
	});
	const data = itemData[0];

	const chartOptions: ApexOptions = {
		colors: [process.env.REACT_APP_WARNING_COLOR],
		chart: {
			type: 'line',
			width: '100%',
			height: 105,
			sparkline: {
				enabled: true,
			},
		},
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: false,
			},
			x: {
				show: false,
			},
			y: {
				title: {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					formatter(seriesName: string) {
						return '';
					},
				},
			},
		},
		stroke: {
			curve: 'smooth',
			width: 2,
		},
	};

	const TABS: ITabs = {
		FILES: 'Files',
		EXCLUDE: 'Exclude',
		CONFIRM: 'Confirm',
		GENRE: 'Genre',
	};
	const [activeTab, setActiveTab] = useState(TABS.FILES);

	const debug = () => {
		if (uploadedFiles != null) {
			console.log(uploadedFiles);
		}
	};

	const [editItem, setEditItem] = useState<IValues>(data);
	const formik = useFormik({
		initialValues: {
			name: '',
			price: 0,
			stock: 0,
			category: '',
		},
		validate,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Updated Successfully</span>
				</span>,
				'Product has been updated successfully',
			);
		},
	});

	const [maxRange, setMaxRange] = useState(0);
	const [totalUploaded, setTotalUploaded] = useState(0);
	const [totalSubmissions, setTotalSubmissions] = useState(0);
	useEffect(() => {
		if (isActivePlan) {
			checkUserUploads();
		}
	}, []);

	const checkUserUploads = async () => {
		console.log(user?.uid);
		if (user && user.emailVerified) {
			const userDocRef = doc(db, 'users', user.uid);
			try {
				const docSnap = await getDoc(userDocRef);
				if (docSnap.exists()) {
					const numberOfUploadsRemaining = docSnap.data().numberOfUploadsRemaining;
					const returnedTotalUploaded = docSnap.data().numberOfFilesUploaded;
					const returnedTotalSubmissions = docSnap.data().numberOfSubmissions;
					if (numberOfUploadsRemaining < 1) {
						navigate('/dashboard');
						showNotification('Error', 'Not enough uploads remaining!', 'danger');
						return;
					}
					setMaxRange(numberOfUploadsRemaining);
					setTotalUploaded(returnedTotalUploaded);
					setTotalSubmissions(returnedTotalSubmissions);
					return numberOfUploadsRemaining;
				} else {
					console.log('No such document!');
					return null;
				}
			} catch (error) {
				console.error('Error getting document:', error);
				return null;
			}
		} else {
			console.log('User is not logged in or email is not verified');
			return null;
		}
	};

	const formik2 = useFormik({
		initialValues: {
			exampleRange: 50,
			exampleDisableRange: 50,
			exampleReadOnlyRange: 50,
			exampleMinMaxRange: 3,
			exampleStep: 0,
		},
		onSubmit: () => {},
	});
	useEffect(() => {
		if (editItem) {
			formik.setValues({
				name: editItem.name,
				price: editItem.price,
				stock: editItem.stock,
				category: editItem.category,
			});
		}
		return () => {
			formik.setValues({
				name: '',
				price: 0,
				stock: 0,
				category: '',
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editItem]);

	// modal states
	const [fileModalState, setFileModalState] = useState(false);
	const [excludeModalState, setExcludeModalState] = useState(false);
	const [genreModalState, setGenreModalState] = useState(false);
	const [headerCloseStatus, setHeaderCloseStatus] = useState(true);
	const [loadingModalState, setLoadingModalState] = useState(false);
	const [warningModalState, setWarningModalState] = useState(false);
	// passed through components
	const [selecetdGenre, setSelectedGenre] = useState<string>('');
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [isInstrumental, setIsInstrumental] = useState(false);
	const [isExplicit, setIsExplicit] = useState(false);
	const [isPublicDomain, setIsPublicDomain] = useState(false);
	const [sitesToRemove, setSitesToRemove] = useState<string[]>([]);
	const [finalizedSites, setFinalizedSites] = useState<string[]>([]);

	// progress bars and loading component states
	const [isLoading, setIsLoading] = useState(false);
	const [isProgress, setIsProgress] = useState(false);
	const [currentNumprogress, setCurrentNumProgress] = useState(0);
	const [submissionId, setSubmissionID] = useState('');
	// for the state of the upload
	const [uploadState, setUploadState] = useState(false);
	// light state of button
	const [lightState, setLightState] = useState(true);

	// states for the buttons
	const [fileSectionCompleted, setFileSectionCompleted] = useState(false);
	const [excludeSectionCompleted, setExcludeSectionCompeted] = useState(false);
	const [genreSectionCompleted, setGenreSectionCompleted] = useState(false);
	const [radioAlbumChecked, setRadioAlbumChecked] = useState(false);

	// next button states
	const toggleExplicit = () => {
		setIsExplicit(!isExplicit);
	};
	const toggleIsInstrumental = () => {
		setIsInstrumental(!isInstrumental);
	};

	const setGenre = (genre: string) => {
		setSelectedGenre(genre);
	};

	const collectionName = 'submissions';
	const fieldsToAdd = {
		genre: selecetdGenre,
		explicity: isExplicit,
		instrumental: isInstrumental,
		numberOfFiles: uploadedFiles.length,
		sitesToDistribute: finalizedSites,
		underProcess: false,
		completed: false,
		userId: user?.uid,
		timestamp: serverTimestamp(),
	};
	const storage = getStorage();

	const addFieldsToCollection = async (collectionName: string, fields: any) => {
		setIsLoading(true);
		// Extract file names from uploadedFiles
		const fileNames = uploadedFiles.map((file) => file.name);

		try {
			// Add a new document with the specified fields to the collection
			const docRef = await addDoc(collection(db, collectionName), {
				...fields,
				fileNames,
			});
			console.log('Document written with ID: ', docRef.id);
			// Set submissionId after document creation
			setSubmissionID(docRef.id);
		} catch (error) {
			console.error('Error adding document: ', error);
			setIsLoading(false);
			throw error;
		}
	};

	useEffect(() => {
		// Call upload function when submissionId changes
		if (submissionId) {
			upload();
		}
	}, [submissionId]);

	useEffect(() => {
		if (uploadedFiles) {
			const isValidFiles = uploadedFiles.every((file) =>
				file.name.toLowerCase().endsWith('.wav'),
			);

			if (!isValidFiles) {
				showNotification('ERROR', 'Please only upload .wav files', 'danger');
				setUploadedFiles([]);
			}
			if (uploadedFiles.length > 0) {
				setFileSectionCompleted(true);
				showNotification('Submission', 'Files successfully uploaded', 'success');
			}
		}
	}, [uploadedFiles]);

	useEffect(() => {
		console.log(selecetdGenre);
		if (selecetdGenre != null && selecetdGenre != '') {
			setGenreSectionCompleted(true);
		}
	}, [selecetdGenre]);

	const upload = async () => {
		console.log(finalizedSites);
		console.log('sites removed is fucka: ' + sitesToRemove);
		if (user && user.emailVerified) {
			if (uploadedFiles != null) {
				// Check if all files are .wav files
				const isValidFiles = uploadedFiles.every((file) =>
					file.name.toLowerCase().endsWith('.wav'),
				);

				if (!isValidFiles) {
					alert('Only .wav files are allowed.');
					return;
				}
				console.log('Upload has started');
				setIsProgress(true);
				for (let i = 0; i < uploadedFiles.length; i++) {
					setCurrentNumProgress(i);
					const file = uploadedFiles[i];
					const storageRef = ref(storage, `${user.email}/${submissionId}/${file.name}`); // Create a reference to the file
					try {
						// Upload file to Firebase Storage
						await uploadBytes(storageRef, file);
					} catch (error) {
						console.error('Error uploading file:', error);
						// Handle error
					}
				}
				if (!radioAlbumChecked && file != null) {
					uploadImage();
				}

				updateUploadsRemaining();
				setIsProgress(false);
				setIsLoading(false);
				navigate('/dashboard');
				showNotification('Success', 'View the submission in my submissions tab', 'success');
			} else {
				console.log('error is null');
			}
		}
	};
	const updateUploadsRemaining = async () => {
		const user = auth.currentUser;
		if (user) {
			const userDocRef = doc(db, 'users', user.uid); // Reference to the user's document
			try {
				const userDoc = await getDoc(userDocRef);
				if (userDoc.exists()) {
					const userData = userDoc.data();
					const currentUploadsRemaining = userData.numberOfUploadsRemaining;
					const newUploadsRemaining = Math.max(
						0,
						currentUploadsRemaining - uploadedFiles.length,
					);
					console.log(newUploadsRemaining);
					const newTotalUploads = totalUploaded + uploadedFiles.length;
					const newTotalSubmissions = totalSubmissions + 1;
					// Update Firestore document
					await updateDoc(userDocRef, {
						numberOfUploadsRemaining: newUploadsRemaining,
						numberOfFilesUploaded: newTotalUploads,
						numberOfSubmissions: newTotalSubmissions,
					});
					console.log('Uploads remaining updated to', newUploadsRemaining);
				} else {
					console.error('User document does not exist!');
				}
			} catch (error) {
				console.error('Error updating document:', error);
			}
		} else {
			console.error('No user logged in!');
		}
	};

	const uploadImage = async () => {
		if (user && user.emailVerified) {
			if (imageSrc != null) {
				const imgFile = file;
				const storageRef = ref(
					storage,
					`${user.email}/${submissionId}/artwork/${imgFile?.name}`,
				);
				try {
					// Upload file to Firebase Storage
					if (imgFile) {
						await uploadBytes(storageRef, imgFile);
					}
				} catch (error) {
					console.error('Error uploading file:', error);
					// Handle error
					setIsProgress(false);
					setIsLoading(false);
				}
			} else {
				console.log('error is null');
			}
		}
	};
	const handleProgreessModal = () => {
		return;
	};
	// set modal functions
	const handleFileModal = () => {
		setFileModalState(!fileModalState);
	};
	const handleExitOnUploadModal = () => {
		showNotification('Error', 'Please wait until the upload is complete', 'danger');
	};
	const handleExcludeModal = () => {
		setExcludeModalState(!excludeModalState);
	};
	const handleGenreModal = () => {
		setGenreModalState(!genreModalState);
	};
	const handleWarningModal = () => {
		setWarningModalState(!warningModalState);
	};
	const handleRadioChange = () => {
		setRadioAlbumChecked(!radioAlbumChecked);
		setImageSrc('');
		setFile(null);
		console.log(radioAlbumChecked);
	};
	const sitesToDistribute = [
		'BMI',
		'Song Trust',
		'Banp Camp',
		'Audio Sparx',
		'Pond 5',
		'MLC',
		'Identifyy',
		'Deposit Photos',
		'Reverb Nation',
		'MCPS',
		'Harry Fox Agency',
		'Rident Royalties',
	];

	// ok so to document this project there is one thousand and one things to complete

	const [imageSrc, setImageSrc] = useState(''); // For image preview
	const [file, setFile] = useState<File | null>(null);
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log('hello');
		if (radioAlbumChecked) {
			<Alert color='danger' icon='warning'>
				You have not selected any sites to exclude
			</Alert>;
			return;
		}
		const newFile = event.target.files ? event.target.files[0] : null;
		if (newFile && (newFile.type === 'image/png' || newFile.type === 'image/jpeg')) {
			const reader = new FileReader();
			reader.onload = (e: ProgressEvent<FileReader>) => {
				if (e.target && e.target.result) {
					setImageSrc(e.target.result as string); // Update image preview
				}
			};
			reader.readAsDataURL(newFile);
			setFile(newFile); // Store the file object
		} else {
			console.error('Unsupported file type or no file chosen.');
			setImageSrc(''); // Reset image source
			setFile(null); // Clear the file object
		}
	};

	const deleteImage = () => {};
	const handleFinalSubmitForFiles = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if ((file != null && !radioAlbumChecked) || (file == null && radioAlbumChecked)) {
			if (uploadedFiles.length > 0 && uploadedFiles.length <= maxRange) {
				setFileSectionCompleted(true);
				setActiveTab(TABS.EXCLUDE);
			} else {
				showNotification('Error', 'Audio files are invalid', 'danger');
			}
		} else {
			showNotification('Error', 'Image file is invalid', 'danger');
		}
	};
	return (
		<PageWrapper title='Submission' isProtected isVerifiedProtected>
			<Page>
				<SubHeader>
					<SubHeaderLeft>
						<Button
							color='info'
							isLink
							icon='ArrowBack'
							onClick={() => navigate('/dashboard')}>
							Back to dashboard
						</Button>
						<SubheaderSeparator />

						<span className='text-muted'>Upload audio</span>
					</SubHeaderLeft>
				</SubHeader>
				<div className='display-4 fw-bold py-3'>New submission</div>
				<div className='row h-100'>
					<div className='col-lg-4'>
						<Card stretch>
							<CardBody>
								<div className='row g-3'>
									<div className='col-12'>
										<Card>
											<CardBody>
												<CardTitle className='my-2'>
													Submisssion guidelines
												</CardTitle>

												<Button
													onClick={handleFileModal}
													icon='Summarize'
													className='my-1'
													color='light'>
													File upload: Guideline
												</Button>
												<Button
													onClick={handleExcludeModal}
													icon='Chat'
													className='my-1'
													color='light'>
													Exclude: Guideline
												</Button>
												<Button
													onClick={handleGenreModal}
													icon='Edit'
													color='light'
													className='my-1'>
													Genre selection: Guideline
												</Button>
											</CardBody>
										</Card>
									</div>
									<div className='col-12 mt-5'>
										<Button
											icon='Summarize'
											color='info'
											className='w-100 p-3'
											isLight={activeTab !== TABS.FILES}
											onClick={() => setActiveTab(TABS.FILES)}>
											{TABS.FILES}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Chat'
											color='info'
											className='w-100 p-3'
											isLight={activeTab !== TABS.EXCLUDE}
											isDisable={!fileSectionCompleted}
											onClick={() => setActiveTab(TABS.EXCLUDE)}>
											{TABS.EXCLUDE}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Edit'
											color='info'
											className='w-100 p-3'
											isLight={activeTab !== TABS.GENRE}
											isDisable={!excludeSectionCompleted}
											onClick={() => setActiveTab(TABS.GENRE)}>
											{TABS.GENRE}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											onClick={() => {
												console.log(sitesToRemove);
											}}></Button>
										<Button
											icon='confirm'
											color='success'
											className='w-100 p-3'
											isLight={activeTab !== TABS.CONFIRM}
											isDisable={!genreSectionCompleted}
											onClick={() => {
												setActiveTab(TABS.CONFIRM);
												setFinalizedSites(
													sitesToDistribute.filter(
														(item) => !sitesToRemove.includes(item),
													),
												);
											}}>
											{TABS.CONFIRM}
										</Button>
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<CardFooterLeft className='w-100'>
									<Button
										icon='Delete'
										color='danger'
										isLight
										className='w-100 p-3'
										onClick={() => {
											navigate('/dashboard');
										}}>
										Delete
									</Button>
								</CardFooterLeft>
							</CardFooter>
						</Card>
					</div>
					<div className='col-lg-8'>
						<Card stretch className='overflow-hidden' noValidate>
							{activeTab === TABS.FILES && (
								<>
									<Wizard
										isHeader
										stretch
										color='info'
										noValidate
										onSubmit={handleFinalSubmitForFiles}
										className='shadow-3d-info'>
										<WizardItem id='step1' title='Artwork'>
											<CardHeader>
												<CardLabel icon='Summarize' iconColor='info'>
													{!radioAlbumChecked ? (
														<div>
															<CardTitle tag='div' className='h5'>
																Upload Your artwork below
															</CardTitle>
															<CardSubTitle tag='div' className='h6'>
																.png or .jpg
															</CardSubTitle>
														</div>
													) : (
														<CardSubTitle tag='div' className='h6'>
															No artwork provided, this will remove
															certain endpoints from your submission
														</CardSubTitle>
													)}
												</CardLabel>
											</CardHeader>
											<div className='row g-4 align-items-center'>
												<div className='col-xl-auto' />
												<div className='col-xl'>
													{!radioAlbumChecked && (
														<div className='row g-4 mb-5'>
															<div className='col-auto'>
																<Input
																	type='file'
																	autoComplete='off'
																	// ariaabel="Choose file"
																	// @ts-ignore
																	accept='.png, .jpg'
																	onMouseDown={(e) =>
																		e.preventDefault()
																	}
																	onChange={handleFileChange}
																/>
															</div>
															<div className='col-auto'>
																<Button
																	color='dark'
																	isLight
																	icon='Delete'
																	onClick={deleteImage}>
																	Delete File
																</Button>
															</div>
														</div>
													)}

													<Checks
														type='switch'
														id='flexSwitchCheckChecked'
														label='Check if you do not wish to provide artwork'
														name='checkedCheck'
														className='mx-2'
														style={{ fontSize: '20px' }}
														onChange={handleRadioChange}
														checked={radioAlbumChecked}
													/>
													{imageSrc && (
														<img
															src={imageSrc}
															alt='Image preview'
															className='mt-5'
															style={{ maxWidth: '600px' }}
														/>
													)}
												</div>
											</div>
										</WizardItem>
										<WizardItem id='step2' title='Notifications'>
											<CardHeader>
												<CardLabel iconColor='info' icon='Summarize'>
													<CardTitle tag='div' className='h5'>
														Upload a folder containing the files you
														want to upload. No more than {maxRange}{' '}
														files
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<Alert isLight icon='Warning'>
												<strong>
													Your track titles will be the file names
												</strong>
											</Alert>
											<CardBody>
												<div className='row'>
													<div className='col lg-12'>
														<Upload
															setUploadedFiles={setUploadedFiles}
															numberOfFilesToUpload={maxRange}
														/>
													</div>
													<Card>
														<CardBody
															isScrollable
															style={{ height: '200px' }}>
															<div className='row g-3'>
																{uploadedFiles.map((i) => (
																	// eslint-disable-next-line react/jsx-props-no-spreading
																	<CardTitle>{i.name}</CardTitle>
																))}
															</div>
														</CardBody>
													</Card>
												</div>
											</CardBody>
										</WizardItem>
									</Wizard>
								</>
							)}
							{activeTab === TABS.EXCLUDE && (
								<>
									<div className='col-12 shadow-3d-container' />
									<CardBody>
										<Card>
											<CardHeader>
												<CardLabel icon='Chat' iconColor='success'>
													<CardTitle>Excluded sites</CardTitle>
													<CardLabel>
														Tick the box on the left to exclude a site
													</CardLabel>
												</CardLabel>
											</CardHeader>
										</Card>
									</CardBody>
									<BoxedSites
										setSitesToRemove={setSitesToRemove}
										sitesToDistribute={sitesToDistribute}
										sitesToRemove={sitesToRemove}
									/>
									<CardLabel>
										If you do not wish to exclude any sites, press confirm
									</CardLabel>

									<Button
										icon='confirm'
										color='success'
										className='w-100 p-3 mb-3'
										isLight={activeTab !== TABS.CONFIRM}
										onClick={() => {
											setFinalizedSites(
												sitesToDistribute.filter(
													(item) => !sitesToRemove.includes(item),
												),
											);
											if (sitesToRemove.length < 1) {
												handleWarningModal();
											}
											setActiveTab(TABS.GENRE);

											setExcludeSectionCompeted(true);
										}}>
										Confirm
									</Button>
								</>
							)}

							{activeTab == TABS.GENRE && (
								<>
									<GenreComponent
										setIsInstrumental={toggleIsInstrumental}
										isInstrumental={isInstrumental}
										setIsExplicit={setIsExplicit}
										isExplicit={isExplicit}
										setSelectedGenre={setGenre}
										selectedGenre=''
										isPublicDomain={isPublicDomain}
										setIsPublicDomain={setIsPublicDomain}
									/>
									<Button
										color='success'
										isDisable={!genreSectionCompleted}
										onClick={() => {
											setActiveTab(TABS.CONFIRM);
										}}>
										Next
									</Button>
								</>
							)}

							{activeTab === TABS.CONFIRM && (
								<>
									<CardBody>
										<Card>
											<CardHeader>
												<CardLabel icon='Upgrade' iconColor='info'>
													<CardTitle className='my-2'>
														Confirm all data before uploading
													</CardTitle>
												</CardLabel>
											</CardHeader>
										</Card>

										<Card style={{ height: '80%' }}>
											<CardHeader>
												<CardLabel icon='Description' iconColor='success'>
													<CardTitle>Submission details</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<Alert isLight icon='Info' color='info'>
													<CardTitle className='my-3'>
														Total number of files to upload:{' '}
														{uploadedFiles.length}
													</CardTitle>

													<div className='row g-3'>
														{selecetdGenre == '' ? (
															<CardTitle>
																Genre Selected: Please select a
																genre
															</CardTitle>
														) : (
															<CardTitle className='my-3'>
																Genre selected: {selecetdGenre}
															</CardTitle>
														)}
													</div>

													<div className='row g-3'>
														{!isInstrumental ? (
															<CardTitle className='my-3'>
																Lyrics: No
															</CardTitle>
														) : (
															<CardTitle className='my-3'>
																Lyrics: Yes
															</CardTitle>
														)}
													</div>
													<div className='row g-3'>
														{isExplicit ? (
															<CardTitle className='my-3'>
																Explicit content: Yes
															</CardTitle>
														) : (
															<CardTitle className='my-3'>
																Explicit content: No
															</CardTitle>
														)}
													</div>

													<div className='row g-3'>
														{!isPublicDomain ? (
															<CardTitle className='my-3'>
																Public Domain: No
															</CardTitle>
														) : (
															<CardTitle>
																Public Domain: Yes
															</CardTitle>
														)}
													</div>
												</Alert>

												<CardBody isScrollable style={{ height: '50%' }}>
													<CardTitle>
														Number of excuded sites:{' '}
														{sitesToRemove.length}
													</CardTitle>
													<div className='row g-3'>
														<Alert isLight>
															{sitesToRemove.map((site, index) => (
																<CardTitle
																	key={index}
																	style={{
																		width: '100%',
																	}}>
																	{site}
																</CardTitle>
															))}
														</Alert>
													</div>
												</CardBody>
											</CardBody>
										</Card>
									</CardBody>
									{isProgress && <div></div>}
									<CardFooter>
										<CardFooterRight>
											<Button
												color={'success'}
												icon='Upload'
												type='submit'
												isDisable={
													!fileSectionCompleted ||
													!genreSectionCompleted ||
													!excludeSectionCompleted
												}
												isLight={lightState}
												onClick={() => {
													addFieldsToCollection(
														collectionName,
														fieldsToAdd,
													);
												}}>
												Upload
											</Button>
										</CardFooterRight>
									</CardFooter>
								</>
							)}
						</Card>
					</div>
				</div>
			</Page>
			<Modal
				isOpen={isProgress}
				setIsOpen={handleProgreessModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<Card className='d-flex justify-content-center align-items-center'>
					<CardBody>
						<ModalTitle id='faq1'>Creating your submission</ModalTitle>
					</CardBody>
				</Card>

				<ModalBody className='d-flex justify-content-center align-items-center'>
					<Progress
						value={currentNumprogress}
						min={0}
						max={uploadedFiles.length}
						height={20} // Example: 10, '3vh', '5rem' etc.
						isStriped
						isAnimated
						isAutoColor={false}
						color='success' // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
					/>
					<Spinner color={'success'} size={15} />

					<CardTitle style={{ marginLeft: '10px' }}>
						File {currentNumprogress} of {uploadedFiles.length}
					</CardTitle>
				</ModalBody>
			</Modal>
			{/* <Modal
				isOpen={isProgress}
				setIsOpen={handleProgreessModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={true}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalTitle id='exampleModalLabel'>
					<strong>Creating your submission</strong>
				</ModalTitle>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<CardTitle style={{ marginLeft: '10px' }}>
							Your submission is being made
						</CardTitle>
						<CardTitle style={{ marginLeft: '10px' }}>
							File {currentNumprogress} of {uploadedFiles.length}
						</CardTitle>
						<Progress
							value={currentNumprogress}
							min={0}
							max={uploadedFiles.length}
							height={10} // Example: 10, '3vh', '5rem' etc.
							isStriped
							isAnimated
							isAutoColor={false}
							color='success' // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
						/>
						<Spinner inButton />
					</div>
				</ModalBody>
			</Modal> */}
			<Modal
				isOpen={fileModalState}
				setIsOpen={handleFileModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleFileModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>File Section</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Accordion id='faq' shadow='sm'>
							<AccordionItem id='faq1' title='Can I upload MP3s?'>
								No, we accept WAV only. This is for the quality of the music we
								distribute on your behalf. To ensure we are maintaining the highest
								quality, we are selective about the format of files we accept.
							</AccordionItem>
							<AccordionItem id='faq2' title='Where will my files go?'>
								Your files will immediately be sent to our server for preperation,
								once processed and verified, your files will immediately be
								distributed to all of your selected sites.
							</AccordionItem>
							<AccordionItem id='faq3' title='Can I edit my data once submitted?'>
								No, however we handle a lot of the data for you. The only real
								important factor for accurate data is you select the most
								appropriate genre that corresponds to your album
							</AccordionItem>
						</Accordion>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isOutline
						className='border-0'
						onClick={() => setFileModalState(false)}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={warningModalState}
				setIsOpen={handleWarningModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleWarningModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Please confirm</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Alert color='danger' icon='warning'>
							You have not selected any sites to exclude
						</Alert>

						<p>
							By continuing, you confirm that these songs do not exist on any of these
							platforms.
						</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color='success'
						onClick={() => {
							setExcludeSectionCompeted(true);
							setActiveTab(TABS.GENRE);
							setWarningModalState(!warningModalState);
						}}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={excludeModalState}
				setIsOpen={handleExcludeModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleExcludeModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Exclude Section</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Accordion id='faq' shadow='sm'>
							<AccordionItem id='faq1' title='When should I exclude a site?'>
								If you are not absolutely certain that every song in your current
								submission is not registered with a site on this list then it must
								be excluded, registering tracks twice with any of the websites will
								cause major issues and there is potential for the submitter to lose
								the submission as a whole.
							</AccordionItem>
							<AccordionItem
								id='faq2'
								title='How do I know if my music exists on these sites?'>
								If you are the writer and legal rights owner of every song in the
								submission then it is a simple matter of if you have distributed
								these songs yourself. If you have acquired the songs from a 3rd
								party source and you are unsure, do not submit the song.
							</AccordionItem>
							<AccordionItem
								id='faq3'
								title='What does excluding a site actually do?'>
								Excluding a site means removing it from the list of platforms that
								this current submission will reach. This setting is only exclusive
								to this current submission and will not affect future submissions.
							</AccordionItem>
						</Accordion>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isOutline
						className='border-0'
						onClick={handleExcludeModal}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={genreModalState}
				setIsOpen={handleGenreModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleGenreModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Genre Section</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12 shadow-3d-container'>
						<Accordion id='faq' shadow='sm'>
							<AccordionItem id='faq1' title='When should I exclude a site?'>
								If you are not absolutely certain that every song in your current
								submission is not registered width a site on this list then it must
								be excluded, registering tracks twice with any of the websites will
								cause major issues and there is potential for the submitter to lose
								the submission as a whole.
							</AccordionItem>
							<AccordionItem
								id='faq2'
								title='How do I know if my music exists on these sites?'>
								If you are the writer and legal rights owner of every song in the
								submission then it is a simple matter of if you have distributed
								these songs yourself. If you have aquired the songs from a 3rd party
								source and you are unsure, do not submit the song.
							</AccordionItem>
							<AccordionItem
								id='faq3'
								title='What does excluding a site actually do?'>
								Excluding a site means removing it from the list of platofrms that
								this current submission will reach. This setting is only exclusive
								to this current submission and will not affect future submissions.
							</AccordionItem>
						</Accordion>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='info' isOutline className='border-0' onClick={handleGenreModal}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default UploadPage;
