import React, { useContext, useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Card, { CardBody, CardTitle, CardHeader, CardLabel } from '../../bootstrap/Card';
import Button from '../../bootstrap/Button';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import AuthContext from '../../../contexts/authContext';
import showNotification from '../../extras/showNotification';
import Checks from '../../bootstrap/forms/Checks';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Textarea from '../../bootstrap/forms/Textarea';
import firebase from '../../../Firebase/firebase';
import Spinner from '../../bootstrap/Spinner';

type QueryData = {
	name: string;
	query: string;
	timestamp: Date;
};
interface ILoginProps {
	isSignUp?: boolean;
}
const Contact = () => {
	const navigate = useNavigate();
	const [userEmail, setUserEmail] = useState('');
	const [userText, setUserText] = useState('');
	const [modalState, setModalState] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { user, isActivePlan } = useContext(AuthContext);
	const { db } = firebase;
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserEmail(e.target.value);
	};

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserText(e.target.value);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			signupemail: 'email@example.com',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string; signupemail?: string } =
				{};

			if (!values.signupemail) {
				errors.signupemail = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {},
	});

	const [useAccountEmail, setUseAccountEmail] = useState(false);

	const handleChangeAccount = () => {
		if (user) {
			setUseAccountEmail(!useAccountEmail);
			if (userEmail != '') {
				setUserEmail('');
			}
			if (user.email != null) {
				setUserEmail(user.email);
			}
		} else {
			showNotification('Error', 'Please create an account', 'info');
		}
	};

	const writeToQueriesCollection = async (queryData: QueryData): Promise<void> => {
		try {
			const docRef = await addDoc(collection(db, 'queries'), queryData);
			setIsLoading(false);
			if (isActivePlan) {
				navigate('/dashboard');
			} else {
				navigate('/');
			}
			showNotification('success', 'We will get back to you shortly', 'success');
		} catch (e) {
			console.log(e);
			showNotification('Error', 'Submit failed', 'danger');
			setIsLoading(false);
		}
	};
	const isValidEmail = (email: string) => {
		const regex = new RegExp(
			/^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
		);
		return regex.test(email);
	};
	const handleShowModal = () => {
		const isValid = isValidEmail(userEmail);
		if (isValid) {
			if (userText.length > 10 && userText.length < 1000) {
				setModalState(!modalState);
			} else {
				showNotification('Error', 'Please provide a valid message', 'danger');
			}
		} else {
			showNotification('Error', 'Invalid email', 'danger');
		}
	};

	const handleFinalSubmit = () => {
		setIsLoading(true);
		const queryData: QueryData = {
			name: userEmail,
			query: userText,
			timestamp: new Date(),
		};
		writeToQueriesCollection(queryData);
	};

	return (
		<PageWrapper title='Contact'>
			<Page>
				<SubHeader>
					<SubHeaderLeft>
						<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
							Back to dashboard
						</Button>
						<SubheaderSeparator />
						<span className='text-muted'>Contact us</span>
					</SubHeaderLeft>
				</SubHeader>
				<Card stretch data-tour='list'>
					<CardHeader>
						<CardLabel icon='ContactMail' iconColor='info' className='mt-5 mx-3'>
							<CardTitle tag='div' className='h3'>
								Get in touch{' '}
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='table-responsive' isScrollable>
						<div className='justify-content-center d-flex'>
							<div className=''>
								<Checks
									type='switch'
									id='flexSwitchCheckChecked'
									label='Check to use your account email'
									name='checkedCheck'
									className='mx-2'
									style={{ fontSize: '20px' }}
									onChange={handleChangeAccount}
									checked={useAccountEmail}
								/>
							</div>
							{!useAccountEmail ? (
								<FormGroup
									id='signupemail'
									isFloating
									label='Your email'
									className='mx-5'
									style={{ width: '50%' }}>
									<Input
										type='email'
										autoComplete='email'
										name='signupemail'
										value={userEmail}
										onChange={handleEmailChange}
										isValid={formik.isValid}
										isTouched={formik.touched.signupemail}
										onBlur={formik.handleBlur}
										invalidFeedback={formik.errors.signupemail}
										onFocus={() => {
											formik.setErrors({});
										}}
									/>
								</FormGroup>
							) : (
								<CardTitle className='mx-5'>{userEmail}</CardTitle>
							)}
						</div>
						<div className='w-100 my-5'>
							<Textarea
								value={userText}
								onChange={handleTextChange}
								placeholder='Enter your message here!'
								style={{ height: '300px' }}></Textarea>
						</div>
						<div className='w-100 my-5'>
							<Button color='info' className='w-100' onClick={handleShowModal}>
								Confirm
							</Button>
						</div>
						<div className='w-100 my-5'>
							<p className='h5'>
								Or send us an email at{' '}
								<span className='text-info'>support@melodyrights.com</span>
							</p>
						</div>
					</CardBody>
				</Card>
			</Page>
			<Modal
				isOpen={modalState}
				setIsOpen={handleShowModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered
				size={'lg'}
				fullScreen={false}
				isAnimation>
				<ModalHeader setIsOpen={handleShowModal}>
					<ModalTitle id='exampleModalLabel'>
						<strong>Confirm</strong>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='container'>
						<p className='h4'>Email - {userEmail}</p>
						<div className='row my-3 mt-5'>Message - {userText}</div>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex justify-content-center'>
					{isLoading ? (
						<Spinner isSmall inButton isGrow />
					) : (
						<Button className='w-100' color='success' onClick={handleFinalSubmit}>
							Submit
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default Contact;
